import React, { useState, useEffect } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import "./App.css";
import "../node_modules/react-grid-layout/css/styles.css"
import "../node_modules/react-resizable/css/styles.css"
const WorkMAPGrid = (props) => {

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

  const [state, setState] = useState({
    videosGridArray: [],
    layouts: JSON.parse(JSON.stringify(originalLayouts))
  });

  function onLayoutChange(layout, layouts) {
    saveToLS("layouts", layouts);
    setState((prevValue) => {
      return {
        ...prevValue,
        layouts
      };
    });
  }

  function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls[key];
  }

  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

  


  document.title = "WorkMAP Grid";





  const layouts = [
    {i: 'a', x: 0, y: 0, w: 1, h: 2, static: true},
    {i: 'b', x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4},
    {i: 'c', x: 4, y: 0, w: 1, h: 2}
  ];

  return (
    <ResponsiveReactGridLayout
    className="layout"
    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    rowHeight={30}
    layouts={state.layouts}
    // onLayoutChange={(layout, layouts) =>
    //   this.onLayoutChange(layout, layouts)
    // }
  >
    <div key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 2, minH: 3 }}>
      <span className="text">1</span>
    </div>
    <div key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}>
      <span className="text">2</span>
    </div>
    <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
      <span className="text">3</span>
    </div>
    <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
      <span className="text">4</span>
    </div>
    <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}>
      <span className="text">5</span>
    </div>
  </ResponsiveReactGridLayout>
  );
};

export default WorkMAPGrid;