import React, { useState } from "react";
import {withRouter} from 'react-router-dom'
import { ContextMenuTrigger } from "react-contextmenu";
import S3FileUpload from 'react-s3';

// Component containing project entnry to display on library page
const Entry = (props) => {

  const [state, setState] = useState({
    currentDraggedProject: "",
  });

  var config = {
    bucketName: "radial-images", 
    dirName: "WorkMAP-images/" + state.currentDraggedProject /* optional */,
    region: "ap-southeast-2",
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  const handleDrop = (e) => {

    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
  
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          var file = e.dataTransfer.items[i].getAsFile();
          console.log("... file[" + i + "].name = " + file.name);
          S3FileUpload.uploadFile(file, config)
            .then((data) => console.log(data))
            .catch((err) => console.error(err));
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        console.log('file[' + i + '].name = ' + e.dataTransfer.files[i].name);
        S3FileUpload.uploadFile(file, config)
          .then((data) => console.log(data))
          .catch((err) => console.error(err));
      }
    }
  };

  // Project image dragover event to prepar for drop for upload to s3
  const handleDragover = (e) => {
      
    e.persist()
    setState((prevValue) => {
      return {
        ...prevValue,
        currentDraggedProject: e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("jobinfo").split(" - ")[0]
      };
    });

    config.dirName = "WorkMAP-images/" + e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("jobinfo").split(" - ")[0]
    e.preventDefault()
  };

  // event to pass to textbar onChange event in Header component - set state to the projects that match the search criteria. The tiles will be rendered from this state change as a result.
  const handleProjectGridDoubleClick = (e) => {

    let type = e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("type")
    switch(type){
      case "project":
        props.handleMenuClick("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getcantilevermodules", e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("jobinfo"))
        break
        case "staff":
        props.handleMenuClick("https://43srautc24.execute-api.ap-southeast-2.amazonaws.com/teststage/getprojectstaff?searchTerm=" + props.currentProject.split(" ")[0], false)
          break
          case "tool":
            props.history.push("/" + e.target.parentElement.parentElement.parentElement.childNodes[1].getAttribute("jobInfo"))
              break
        default: console.log("error")
    }
  };

  return (
    <div
      className="term"
      onClick={handleProjectGridDoubleClick}
      style={{ backgroundColor: props.colour, color: "white" }}
    >
      <dt>
        <ContextMenuTrigger id="same_unique_identifier">
          <img
            src={props.imageUrl}
            alt="central images"
            width="220"
            height="180"
            onContextMenu={props.onRightClick}
            onDrop={handleDrop}
            onDragOver={handleDragover}
          />
        </ContextMenuTrigger>
      </dt>
      <dd width="220" jobinfo={props.description} type={props.type} style={{ color: "black", fontSize: 12 }}>
        {props.description}
      </dd>
      <dd width="220" style={{ color: "black", fontSize: 10 }}>
        {props.architect}
      </dd>
      {props.showMoreOrLessOnCards === "Show less..." && <dd width="220" style={{ color: "black", fontSize: 10 }}>
        Team leader
      </dd> }
    </div>
  );
};

export default withRouter(Entry);