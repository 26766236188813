import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./Login.css";
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
  } from "amazon-cognito-identity-js";
import AWSLogin, {CognitoSignOut, CognitoCheckSession} from './loginUtil'

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  document.title="WorkMAP - Login"

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = async event => {
    // override submit button behaviour and check user login details with AWS Cognito - set authenticated prop to true if successful otherwise set to false
    event.preventDefault();
    let result = await AWSLogin(email, password)
    props.userHasAuthenticated(result);
  }



  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bssize="large">
          <FormLabel>Username</FormLabel>
          <FormControl
            autoFocus
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bssize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bssize="large" onClick={handleSubmit} type="submit">
          Login
        </Button>
      </form>
    </div>
  );
}